export default function navigation_init() {
  $('#content img').each(function() {
    if (!$(this).parent().is('a')) {
      const imgSrc = $(this).attr('src');
      const imgSrcSet = $(this).attr('srcset');
      let firstSrc = imgSrc;

      if (imgSrcSet) {
        const srcArray = imgSrcSet.split(',');
        const firstSrcSet = srcArray[0].trim();
        const srcUrl = firstSrcSet.split(' ')[0];
        firstSrc = srcUrl;
        console.log(srcUrl)
      }

      const $a = $('<a>');
      $a.attr('href', firstSrc);
      $a.attr('data-fancybox', 'page');

      $(this).wrap($a);
    }
  });

  $('.brands').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnFocus: false,
    pauseOnHover: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  })

  const video = $('header video');
  const img = $('header img');
  if(video.length) {
    if (video[0].readyState >= 2) {
      $(document).ready(() => {
        $('.loader').addClass('hide');
      })
    } else {
      video[0].addEventListener('loadeddata', function() {
        $('.loader').addClass('hide');
      }, false);
    }
  }
  else if(img.length) {
    if(img[0].complete) {
      $(document).ready(() => {
        $('.loader').addClass('hide');
      })
    } else {
      img[0].addEventListener('load', function() {
        $('.loader').addClass('hide');
      })
    }
  } else {
    $(document).ready(() => {
      $('.loader').addClass('hide');
    })
  }

  $('header .scroll-icon').click(() => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  })

  const scrollTop = $('.scrollTop')
  scrollTop.click(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  })
  $(window).scroll(() => {
    if(window.scrollY > 100) {
      scrollTop.addClass('show')
    } else {
      scrollTop.removeClass('show')
    }
  })

  const soundControlButton = $('.sound-control')
  soundControlButton.click(function() {
    if(!video.length) return
    if($(this).hasClass('switch')) {
      video.prop('muted', true);
    } else {
      video.prop('muted', false);
    }
    soundControlButton.toggleClass('switch')
  })
}