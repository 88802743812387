export default function home_init() {
  const more = 'Więcej'
  const less = 'Ukryj'
  $('.collapse-button').click(function() {
    const name = this.dataset.collapse
    const collapseContent = $('.collapse-content[data-collapse="'+name+'"]')
    collapseContent.toggleClass('expanded')
    if(collapseContent.hasClass('expanded')) {
      $(this).text(less)
    } else {
      $(this).text(more)
    }
  })
}