import navigation from './navigation'
import home from './home'
import page from './page'
import tabs from './tabs'
import contact from './contact'
import './cookies'

navigation()
home()
page()
tabs()
contact()

AOS.init({
  duration: 700
});